// @flow
import React, { Component } from 'react';
import type { LineItem } from '../types';

//Components
import { Link } from 'react-router-dom';
import { ItemListImage, quantityConverter } from '../../common';
import AddToCartButton from '../../current-order/containers/AddToCartButton';
import InputFieldErrorMessage from '../../common/components/InputFieldErrorMessage';
import { Button, Checkbox } from '../../common';
import Slider from 'rc-slider';

// Actions
import { changeLineItem } from '../actions';

//Selector
import { editingItemLoadingByIdSelector } from '../selectors';

// Composers
import { connect } from 'react-redux';

//Style
import './Item.css';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

// Assets
import TrashCan from '../images/trash-can-icon.svg';
import InterrogationMark from '../images/interrogation-mark.svg';
import Receipt from '../images/receipt-add.svg';
import PortioningValuesBox from './PortioningValuesBox';

type OwnProps = {
  productId: number,
};

type Props =
  | {
      item: LineItem,
      updatingItemLoading: boolean,
      handleRemoveItem: (lineItemId: number) => void,
    }
  | OwnProps;

type State = {
  observation: string,
  badObservation: string,
  isPortioned: boolean,
  portioningSize: number,
};

class Item extends Component {
  state: State;
  constructor(props: Props) {
    super(props);

    this.state = {
      observation: this.props.item.observation || '',
      badObservation: '',
      isPortioned: !!this.props.item.portioningSize,
      portioningSize: this.props.item.portioningSize || null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.item.portioningSize !== prevProps.item.portioningSize &&
      this.props.item.portioningSize !== this.state.portioningSize
    ) {
      this.setState({
        isPortioned: !!this.props.item.portioningSize,
        portioningSize: this.props.item.portioningSize,
      });
    }
  }

  renderDeleteItemButton(classname: string) {
    return (
      <div className={classname}>
        <button
          className="item-list-trash-button"
          aria-hidden="true"
          onClick={() => this.props.handleRemoveItem(this.props.item.id)}
          disabled={this.props.updatingItemLoading}
        >
          <img
            src={TrashCan}
            alt="TrashCanIcon"
            className="item-list-trash-icon"
          ></img>
        </button>
      </div>
    );
  }

  generateMarks(minQuantity, maxQuantity) {
    const marks = {};
    const startMark = minQuantity >= 1000 ? minQuantity : 1000;
    for (let i = startMark; i <= maxQuantity; i += 1000) {
      marks[i] = `${i / 1000}kg`;
    }
    return marks;
  }

  render() {
    const item = this.props.item;
    const verifyValue = value => value === null || value === '' || value === 0;

    const isEqualFields =
      ((verifyValue(item.observation) && verifyValue(this.state.observation)) ||
        item.observation === this.state.observation) &&
      ((verifyValue(item.portioningSize) &&
        verifyValue(this.state.portioningSize)) ||
        item.portioningSize === this.state.portioningSize);

    const minQuantity = Math.max(
      Math.ceil(item.quantity / 30 / 100) * 100,
      500,
    );
    const maxQuantity = Math.min(10000, item.quantity);
    const incrementStep = 100;

    return (
      <div>
        <div className="item-list-main-container">
          <div className="item-list-image-container">
            {this.renderDeleteItemButton('item-list-trash-can-icon')}
            <ItemListImage variant={item.variant} />
          </div>
          <div className="item-list-content">
            <div className="item-list-header">
              <div className="line-item-content">
                <p>{item.variant.manufacturer}</p>
                <Link to={`/produtos/${item.variant.slug}/`}>
                  <h3 className="line-item-name-text">{item.variant.name}</h3>
                </Link>
              </div>
              {this.renderDeleteItemButton('line-item-price-container-option')}
            </div>
            <div className="item-list-container">
              {item.variant.unitForQuantity === 'g' ? (
                <>
                  <div className="item-portioning-edit-container">
                    <div className="item-portioning-main-checkbox">
                      <h5>Porcionar?</h5>
                      <div className="item-portioning-tooltip-container">
                        <img
                          src={InterrogationMark}
                          alt="InterrogationMark"
                          onMouseEnter={() =>
                            this.setState({ showTooltip: true })
                          }
                          onMouseLeave={() =>
                            this.setState({ showTooltip: false })
                          }
                          onTouchStart={() =>
                            this.setState({
                              showTooltip: !this.state.showTooltip,
                            })
                          }
                        />
                        {this.state.showTooltip && (
                          <div className="item-portioning-tooltip-message">
                            O porcionamento é feito para que você possa ter o
                            seu produto separado do seu jeito!
                          </div>
                        )}
                      </div>
                      <div className="item-portioning-checkbox-item">
                        <Checkbox
                          label={<span></span>}
                          checked={this.state.isPortioned}
                          onChange={() => {
                            this.setState({
                              isPortioned: !this.state.isPortioned,
                            });
                            if (this.state.isPortioned) {
                              this.setState({ portioningSize: 0 });
                            }
                          }}
                        />
                      </div>
                    </div>
                    {this.state.isPortioned &&
                    item.variant.unitForQuantity === 'g' ? (
                      <>
                        <div className="item-portioning-edit">
                          <div className="item-slider-content">
                            <Slider
                              min={minQuantity}
                              max={maxQuantity}
                              step={incrementStep}
                              marks={this.generateMarks(
                                minQuantity,
                                maxQuantity,
                              )}
                              defaultValue={
                                this.state.portioningSize >= minQuantity
                                  ? this.state.portioningSize
                                  : minQuantity
                              }
                              value={this.state.portioningSize || minQuantity}
                              onChange={value => {
                                this.setState({ portioningSize: value });
                              }}
                              disabled={!this.state.isPortioned}
                              trackStyle={{ backgroundColor: '#eb5b1e' }}
                              handleStyle={{ borderColor: '#ce3c25' }}
                            />
                          </div>
                          <div className="item-portioning-size-block">
                            <span>
                              {quantityConverter(
                                this.state.portioningSize,
                                item.variant.unitForQuantity,
                              )}
                            </span>
                          </div>
                        </div>

                        {(this.state.portioningSize && (
                          <PortioningValuesBox quantity={item.quantity} portioningSize={this.state.portioningSize}/>

                          
                        )) ||
                          null}
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}

              <div className="line-item-observation-main">
                <form>
                  <InputFieldErrorMessage message={this.state.badObservation} />
                  <textarea
                    placeholder="Observações para a produção"
                    value={this.state.observation}
                    onChange={e =>
                      e.target.value.length < 100 &&
                      this.setState({
                        observation: e.target.value,
                        badObservation:
                          e.target.value.length === 99
                            ? 'Observação muito longa'
                            : '',
                      })
                    }
                  ></textarea>
                </form>
              </div>
              <div className="item-btn-save-observation">
                <Button
                  text={isEqualFields ? 'Salvo' : 'Salvar observação'}
                  buttonType={
                    isEqualFields ? 'button-tertiary' : 'button-secondary'
                  }
                  icon={!isEqualFields ? Receipt : ''}
                  loading={this.props.updatingItemLoading}
                  disabled={isEqualFields}
                  onClick={e => {
                    this.props.handleEdit(
                      this.state.observation,
                      this.state.portioningSize,
                    );
                  }}
                />
              </div>
            </div>

            <div className="item-list-price-container">
              <div className="line-item-price-content">
                <h5>Preço/Kg</h5>
                <AddToCartButton
                  productId={item.variant.id}
                  unitForQuantity={item.variant.unitForQuantity}
                  defaultQuantity={item.quantity}
                  incrementQuantity={item.variant.incrementQuantity}
                  inStock={item.variant.inStock}
                  discontinued={item.variant.discontinued}
                  disableInsteadOfDeleteOnMinimumQuantity={true}
                  observation={this.state.observation}
                  analyticsEventType={'cart'} // USE ANALYTICS
                />
              </div>
              <div className="line-item-prices-container">
                <h6 className="line-item-product-price">
                  {parseFloat(
                    item.variant.unitForQuantity === 'g'
                      ? item.variant.price * 1000
                      : item.variant.price,
                  ).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}{' '}
                  /{item.variant.unitForQuantity === 'g' ? 'Kg' : 'un'}
                </h6>
                <h6 className="line-item-product-total-price">
                  {parseFloat(
                    item.variant.price * item.quantity,
                  ).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps: OwnProps) => {
  return {
    updatingItemLoading: editingItemLoadingByIdSelector(
      state,
      ownProps.productId,
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    handleEdit: (observation: string, portioningSize: number) => {
      dispatch(changeLineItem(ownProps.productId, observation, portioningSize));
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default connector(Item);
