import { Props } from './BuyBox'
import { quantityConverter } from '../../common';
import Slider from 'rc-slider';
import PortioningValuesBox from './PortioningValuesBox';

const PortionSlider = ({ product, item, clickHandler }: Props) => {

  const generateMarks = (minQuantity, maxQuantity) => {
    const marks = {};
    const startMark = minQuantity >= 1000 ? minQuantity : 1000;
    for (let i = startMark; i <= maxQuantity; i += 1000) {
      marks[i] = `${i / 1000}kg`;
    }
    return marks;
  }

  const minQuantity = Math.max(
    Math.ceil(item.quantity / 30 / 100) * 100,
    500,
  );
  const maxQuantity = Math.min(10000, item.quantity);
  const incrementStep = 100;


  return <>
  <div className="item-portioning-edit">
    <div className="item-slider-content">
      <Slider
        min={minQuantity}
        max={maxQuantity}
        step={incrementStep}
        marks={generateMarks(
          minQuantity,
          maxQuantity,
        )}
        defaultValue={
          item.portioningSize >= minQuantity
            ? item.portioningSize
            : minQuantity
        }
        value={item.portioningSize || minQuantity}
        onChange={value => {
          clickHandler(value)
        }}
        trackStyle={{ backgroundColor: '#eb5b1e' }}
        handleStyle={{ borderColor: '#ce3c25' }}
      />
    </div>
    <div className="item-portioning-size-block">
      <span>
        {quantityConverter(
          item.portioningSize,
          item.variant.unitForQuantity,
        )}
      </span>
    </div>
  </div>

  {(item.portioningSize && (
    <PortioningValuesBox quantity={item.quantity} portioningSize={item.portioningSize}/> 
  )) ||
    null}
</>
}

export default PortionSlider