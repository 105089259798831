import './PortioningValuesBox.css';
import CartBox from '../images/cart-box-icon.svg'

const PortioningValuesBox = ({quantity, portioningSize}) => {

  const portionNumber = Math.floor((quantity / portioningSize))
  const remainder = quantity - (portioningSize * portionNumber)

 return (
  <div class="package-info">
   <div className="icon">
    <img src={CartBox} />
   </div> 
   <div class="text">
     <div class="title">Seu pedido será embalado dessa forma</div>
     {portionNumber > 0  && 
      <div class="info">
        <span className="number">{portionNumber}</span> embalagem de {portioningSize} gramas
      </div>
     }
     {remainder > 0 && <div class="info"><span className="number">1</span> embalagem de {remainder} gramas</div>}
   </div>
  </div>
  )
}
export default PortioningValuesBox